import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const USER = "/education/schschool/";

/**
 * 获取学校表键值对
 * @author 四川创企
 * @DateTime 2023/04/11
 * @param {object} key
 * @param {object} value
 * @param {int} id
 */
export async function getSchSchoolKeyVal(key, value ,id,query) {
    try {
        return await API.post(USER + "getkeyval", {
            key: key,
            value: value,
			id:id,
			query:query
        });
    } catch (error) {
        return error;
    }
}
/**
 * 获取班级
 * @author 四川创企
 * @DateTime 2023/04/11
 * @param {int} scsch_id 学校id
 */
export async function getSchClassTreeList(scsch_id) {
    try {
        return await API.post(USER + "get_education_class",{scsch_id});
    } catch (error) {
        return error;
    }
}

/**
 * 获取教师授课班级
 * @author 四川创企
 * @DateTime 2023/04/11
 * @param {object} key
 * @param {object} value
 * @param {int} id
 */
export async function getTeaClass(id) {
    try {
        return await API.post(USER + "get_tea_class",{teuser_id:id});
    } catch (error) {
        return error;
    }
}





