import API from "@utils/request";
import { PAGE_SIZE } from "@config";

const USER = "/education/teaclassschedule/";


/**
 * 获取课程列表（分页）
 * @param {object} params
 * @param {object} pages
 */
export async function getList(
  params,
  pages = {
    pageindex: 1,
    pagesize: PAGE_SIZE,
  }
) {
  try {
    return await API.get(USER + "list", {
      params: {
        ...params,
        ...pages,
      },
      headers: { isLoading: true },
    });
  } catch (error) {
    return error;
  }
}




// 获取课程详情
export async function getCourseInfo(id) {
  try {
    return await API.post(USER + "info", {
		tecla_id:id,
		
	});
  } catch (error) {
    return error;
  } 
}

/**
 * 获取课堂作业详情
 * @author 四川创企
  * @DateTime 2023/04/26
  * @param {number} id
 */
export async function getPracticeInfo(id) {	
	try {
		return await API.post(USER + "get_practice_info", {
			tetea_pra_id: id
		});
	} catch (error) {
		return error;
	}
}

/**
 * 获取课堂作业详情
 * @author 四川创企
  * @DateTime 2023/04/26
  * @param {number} id
 */
export async function getHomeWorkInfo(id) {
	try {
		return await API.post(USER + "get_homeWork_info", {
			tetea_hom_id: id
		});
	} catch (error) {
		return error;
	}
}
/**
 * 导出信息
 * @author 四川创企
  * @DateTime 2023/04/26
  * @param {number} id
 */
export async function exportData(params) {
	try {
		return await API.post(USER + "export", params);
	} catch (error) {
		return error;
	}
}







