<style scoped lang="scss">
.curriculum {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.look-btn {
  width: 60px;
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: #6340c8;
  background-color: #f2edff;
  border-radius: 6px;
  border: solid 1px #c0adf9;

  &:hover {
    color: #ffffff;
    background-color: #6340c8;
    border: 1px solid #6340c8;

    &:before,
    &:after {
      background: #6340c8;
    }
  }
}

.curriculum-search {
  flex: none;
}

.curriculum-table {
  flex: 1;
  overflow: hidden;

  .table_center {
    height: calc(100% - 60px);
    overflow-y: scroll;
  }
}

// 分页
.paging {
  padding: 10px 0;
  text-align: right;
}

// 新增课程
.new-courses {
  width: 100%;
  height: 68px;
  line-height: 68px;
  background-color: #f6f7fc;
  font-size: 16px;
  font-weight: 600;
  color: #1b1b1b;
  position: relative;
  text-indent: 36px;
}

.newcourses {
  .new-courses-content {
    background: #ffffff;
    padding: 50px 90px 20px;
    box-sizing: border-box;
  }
}

.new-courses::after {
  content: "";
  width: 5px;
  height: 18px;
  background-color: #6340c8;
  border-radius: 2.5px;
  position: absolute;
  top: 26px;
  left: 25px;
}

::v-deep {
  .newcourses .el-dialog {
    border-radius: 10px;
    overflow: hidden;
  }

  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__body {
    padding: 0;
  }

  .curriculum-search .el-input--medium .el-input__inner {
    min-width: 250px;
    background: #f1f2f8;
    border-radius: 10px;
    border: none;
  }

  .curriculum-search .el-form--inline .el-form-item {
    margin-right: 19px;
  }
}

// 新增课程
::v-deep {
  .new-courses-content .el-input--medium .el-input__inner {
    height: 46px;
    line-height: 46px;
    width: 393px;
    background: #f6f7fa;
    border: none;
    border-radius: 10px;
  }

  .new-courses-content .el-form-item--medium .el-form-item__label {
    line-height: 46px;
  }

  .el-dialog .el-dialog__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
<template>
  <section class="curriculum">
    <div class="curriculum-search">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="学校:" prop="scsch_id">
          <el-select
            v-model="searchForm.scsch_id"
            placeholder="请选择"
            :filter-method="handleSchool"
            clearable
            filterable
          >
            <el-option
              v-for="item in scsch_idoption"
              :key="item.value"
              :label="item.key"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级:" prop="sccla_id">
          <el-cascader
            v-model="searchForm.sccla_id"
            :options="sccla_id_treeoption"
            :props="{
              checkStrictly: false,
              emitPath:false,
              value: 'key',
              label: 'label',
            }"
            clearable
          ></el-cascader>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit" class="lightpurple-btn"
            >查询</el-button
          >
          <el-button type="primary" @click="exportData" class="lightyellow-btn" v-loading="btnLoad"
            >导出</el-button
          >
          <!-- <el-button
            type="primary"
            @click="dialogTableVisible = true"
            class="lightgreen-btn"
            >新增
          </el-button> -->
        </el-form-item>
      </el-form>
    </div>

    <div class="curriculum-table">
      <template>
        <div class="table_center">
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{
              background: '#E8EAF3',
              color: '#1a1a1b',
              'text-align': 'center',
              fontWeight: 500,
            }"
            v-loading="dataload"
          >
            <el-table-column prop="tecla_id" label="ID"> </el-table-column>
            <el-table-column prop="sysub_name" label="科目"> </el-table-column>
            <el-table-column prop="teuse_name" label="授课教师">
            </el-table-column>
            <el-table-column prop="tecla_grade_name" label="年级">
            </el-table-column>
            <el-table-column prop="sccla_name" label="班级"> </el-table-column>
            <el-table-column label="上课时间">
              <template slot-scope="scope">
                {{ scope.row.week }}|{{ scope.row.sccla_sch_tim_starttime }}
              </template>
            </el-table-column>
            <el-table-column prop="sccou_cha_title" label="课程内容">
            </el-table-column>

            <el-table-column label="操作" width="360">
              <template slot-scope="scope">
                
                <el-button
                  type="primary"
                  class="look-btn green-btn"
                  @click="
                    $router.push({
                      name: 'COURSE_INFO',
                      params: {
                        tecla_id:scope.row.tecla_id,
                      },
                    })
                  "
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>

        <div class="paging">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="10"
            next-text="下一页"
            layout=" pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </template>
    </div>

    <!-- 新增课程 -->
    <el-dialog
      :show-close="false"
      width="659px"
      class="newcourses"
      :visible.sync="dialogTableVisible"
    >
      <div class="new-courses">新增课程</div>

      <div class="new-courses-content">
        <el-form label-width="80px" :model="newcoursesData">
          <el-form-item label="科目" prop="region">
            <el-select v-model="newcoursesData.name" placeholder="请选择科目">
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="课时">
            <el-input v-model="newcoursesData.name"></el-input>
          </el-form-item>

          <el-form-item label="上课时间" prop="region">
            <el-select
              v-model="newcoursesData.name"
              placeholder="请选择上课时间"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上课班级" prop="region">
            <el-select
              v-model="newcoursesData.name"
              placeholder="请选择上课班级"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上课方式" prop="region">
            <el-select
              v-model="newcoursesData.name"
              placeholder="请选择上课方式"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程内容">
            <el-input v-model="newcoursesData.name"></el-input>
          </el-form-item>
          <el-form-item label="授课教师" prop="region">
            <el-select
              v-model="newcoursesData.name"
              placeholder="请选择授课教师"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="适合年级" prop="region">
            <el-select
              v-model="newcoursesData.name"
              placeholder="请选择适合年级"
            >
              <el-option label="区域一" value="shanghai"></el-option>
              <el-option label="区域二" value="beijing"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            style="
              margin-top: 49px;
              padding-top: 26px;
              text-align: right;
              border-top: 1px solid #f8f8f8;
            "
          >
            <el-button
              type="primary"
              class="lightgrey-btn"
              @click="dialogTableVisible = false"
              >取消
            </el-button>
            <el-button class="lightpurple-btn" type="primary">确定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { getList, getInfo ,exportData} from "@api/course";
import { getSchSchoolKeyVal, getSchClassTreeList } from "@api/SchSchool";
export default {
  name: "",
  data() {
    return {
      currentPage: 1,
      total: 120,
      size: 10,
      btnLoad:false,
      dataload:false,
      searchForm: {      
        scsch_id: "",
        sccla_id: "",
        // classTime: "",
      },
      scsch_idoption: [], //学校下拉
      sccla_id_treeoption: [], //班级数据

      // 表格数据
      tableData: [],

      // 新增课程
      dialogTableVisible: false,

      newcoursesData: {
        name: "",
      },
    };
  },

  mounted() {
    // let doc = document.querySelector(".el-pagination");
    // let liElement = document.createElement("button");
    // liElement.setAttribute("class", "surebtn");
    // var liText = document.createTextNode("确定");
    // liElement.appendChild(liText);
    // doc.appendChild(liElement);
    this.getData();
  },
  methods: {
    //查询按钮
    onSubmit() {
      this.getData();
    },
    handleClick() {
      this.$router.push({
        path: "/course/info",
      });
      // this.$router.push({path: '/login?url=' + this.$route.path});
    },
    async getData() {
      this.dataload=true;
      //获取学校数据
      let { data: resscsch_id } = await getSchSchoolKeyVal(
        "scsch_name",
        "scsch_id"
      );
      this.scsch_idoption = resscsch_id;

      // 获取教育局下的班级信息
      let { data: resclass_data } = await getSchClassTreeList();
      this.sccla_id_treeoption = resclass_data;

      //课程数据
      const pages = {
        pageindex: this.currentPage,
        pagesize: this.size,
      };
      var res;
      const { data: resdata } = await getList(this.searchForm, pages);
      res = resdata;
      this.tableData = res.data; //数据
      this.total = res.allcount; //总数
      this.currentPage = res.pageindex; //当前页
      this.dataload=false;
    },
    //导出
    async exportData(){
   
      this.btnLoad = true;
            let { data } = await exportData(this.searchForm);
            
            this.btnLoad = false;
            if (data.full_file_url) {
                this.$fileDownload(data.full_file_url)
            };
    },
    //学校搜索
    async handleSchool(query) {
      if (query !== "") {
        let { data: resscsch_id } = getSchSchoolKeyVal(
          "scsch_name",
          "scsch_id",
          this.searchForm.scsch_id,
          query
        ).then((res) => {
          if (res.status == 200) {
            this.scsch_idoption = res.data;
          } else {
            this.scsch_idoption = [];
          }
        });
      }
    },

    // 修改当前
    handleCurrentChange() {
      this.currentPage = this.currentPage;
      this.getData()
    },
  },
};
</script>
